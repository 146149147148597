<template>
  <v-row
    justify="space-between"
    class="head-search ma-0 rounded">
    <v-col
      cols="4"
      class="d-flex align-center date-picker-col">
      <date-range-picker
        v-model="saleDates"
        @change="dateChange($event)" />
    </v-col>
    <v-spacer />
    <v-col
      cols="4"
      class="d-flex align-center date-picker-col">
      <v-spacer />
      <v-btn
        class="mx-2"
        color="primary"
        :loading="loading"
        @click="find()">
        find
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue'

export default {
  components: {
    DateRangePicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      saleDates: [this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
    }
  },
  watch: {
    saleDates: {
      handler (value) {
        this.$emit('change-date', value)
      },
      deep: true
    }
  },
  methods: {
    dateChange (date) {
      this.$emit('change-date', date)
    },
    find () {
      const payload = {
        startDate: this.$dayjs(this.saleDates[0]).utc().toISOString(),
        endDate: this.$dayjs(this.saleDates[1]).add(1, 'day').utc().toISOString()
      }

      this.$emit('find-clicked', payload)
    }
  }
}
</script>
