<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row v-if="datas">
      <v-col cols="12">
        <ChatCofenReportHeader
          :loading="loading"
          @change-date="changeDate($event)"
          @find-clicked="find($event)" />
      </v-col>
      <v-col
        v-for="(data, index) in datas"
        :key="index"
        md="3"
        cols="6">
        <v-card
          outlined
          class="stats-card ma-0">
          <v-card-text class="text-center">
            <div style="text-transform: uppercase;">
              {{ data.channel }}
            </div>
            <p
              class="text--primary"
              style="font-size: 18px; font-weight: bold;">
              {{ data.brand }}
            </p>
            <p
              class="text--primary"
              style="font-size: 28px; font-weight: bold;">
              {{ data.count }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'
import ChatCofenReportHeader from '../components/ChatCofenReportHeader.vue'

const ChatService = new ChatProvider()
export default {
  components: {
    ChatCofenReportHeader
  },
  data () {
    return {
      datas: [],
      dates: [null, null],
      loading: false
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    changeDate (date) {
      this.dates = date
    },
    find (payload) {
      this.fetchData(payload)
    },
    async fetchData (payload) {
      try {
        this.data = null
        this.loading = true

        const { data } = await ChatService.getChatReport(payload)
        this.datas = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.stats-card {
  min-width: 15%;
  margin: 8px;
}
</style>
