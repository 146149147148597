import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ChatProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getChatReport (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/social-chat/report', query)
  }

  getChatFeedbackReport (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/surveys/report', query)
  }
}

export default ChatProvider
